<template>
	<div
		id="notificationModal"
		aria-labelledby="notificationModalSubject"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal__header">
					<h3 class="modal-title" id="notificationModalSubject">Send Notification</h3>
					<button
						@click.prevent="clearErrors()"
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div class="modal-body modal__body">
					<form class="modal__form" method="post" @submit.prevent="sendNotifications">
						<div class="form-row">
							<div class="form-group col-md-12">
								<label class="modal__label" for="subject">Subject</label>
								<input
									:class="[{ 'is-invalid': errors.subject }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.subject"
									name="subject"
									type="text"
									class="form-control"
									id="subject"
									placeholder="Message Subject"
								/>
								<div class="invalid-feedback">
									{{ errors.subject }}
								</div>
							</div>
							<div class="form-group col-md-12">
								<label class="modal__label" for="body">Message Body</label>
								<ckeditor :editor="editor" v-model="itemData.body" :config="editorConfig" />
								<div class="invalid-feedback">
									{{ errors.body }}
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer modal__footer">
					<button
						@click.prevent="clearErrors()"
						class="btn btn--md"
						type="button"
						data-dismiss="modal"
						aria-label="close"
						name="button"
					>
						Cancel
					</button>
					<button
						class="btn btn--md btn__primary"
						type="submit"
						@click.prevent="sendNotifications"
						v-bind="{ disabled: loading }"
						name="button"
					>
						Send Notification
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import form from '../../mixins/form';
import messages from '../../utils/validations/messages';

export default {
	name: 'Notification',
	mixins: [form],
	data() {
		return {
			editor: ClassicEditor,
			editorConfig: {},
			itemData: {},
			errors: {},
			loading: false
		};
	},

	components: {
		ckeditor: CKEditor.component
	},
	computed: {
		...mapGetters(['getStatus'])
	},
	watch: {
		getStatus(status) {
			if (status === 'success') {
				this.itemData = {};
			}
		}
	},
	methods: {
		...mapActions(['sendNotification']),
		sendNotifications() {
			this.validate(this.itemData, messages.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}

			return this.sendNotification(this.itemData);
		}
	}
};
</script>
