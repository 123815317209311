<template>
	<div
		id="emailModal"
		aria-labelledby="emailModalTitle"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal__header">
					<h3 class="modal-title" id="emailModalTitle">Send Email</h3>
					<button
						@click.prevent="clearErrors()"
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div class="modal-body modal__body">
					<form class="modal__form" method="post">
						<div class="form-row">
							<div class="form-group col-md-12">
								<label class="modal__label" for="title">Subject</label>
								<input
									:class="[{ 'is-invalid': errors.subject }]"
									@keydown="clearErrors($event.target.name)"
									name="subject"
									type="text"
									class="form-control"
									id="title"
									placeholder="Email Subject"
									v-model="itemData.subject"
								/>
								<div class="invalid-feedback">
									{{ errors.subject }}
								</div>
							</div>
							<div class="form-group col-md-12">
								<label class="modal__label" for="recipients">Recipients</label>
								<select v-model="itemData.recipients" class="form-control" id="recipients">
									<option value="all">All</option>
									<option value="individual">Individual</option>
								</select>
							</div>
							<div class="form-group col-md-4" v-if="itemData.recipients === 'individual'">
								<label class="modal__label" for="firstname">First Name</label>
								<input
									:class="[{ 'is-invalid': errors.firstname }]"
									@keydown="clearErrors($event.target.name)"
									name="firstname"
									type="text"
									class="form-control"
									id="firstname"
									placeholder="First Name"
									v-model="itemData.firstname"
								/>
								<div class="invalid-feedback">
									{{ errors.firstname }}
								</div>
							</div>
							<div class="form-group col-md-4" v-if="itemData.recipients === 'individual'">
								<label class="modal__label" for="lastname">Last Name</label>
								<input
									:class="[{ 'is-invalid': errors.lastname }]"
									@keydown="clearErrors($event.target.name)"
									name="lastname"
									type="text"
									class="form-control"
									id="lastname"
									placeholder="Last Name"
									v-model="itemData.lastname"
								/>
								<div class="invalid-feedback">
									{{ errors.lastname }}
								</div>
							</div>
							<div class="form-group col-md-4" v-if="itemData.recipients === 'individual'">
								<label class="modal__label" for="email">Email</label>
								<input
									:class="[{ 'is-invalid': errors.email }]"
									@keydown="clearErrors($event.target.name)"
									name="email"
									type="email"
									class="form-control"
									id="email"
									placeholder="Email"
									v-model="itemData.email"
								/>
								<div class="invalid-feedback">
									{{ errors.email }}
								</div>
							</div>
							<div class="form-group col-md-12">
								<label class="modal__label" for="body">Email Body</label>
								<ckeditor :editor="editor" :config="editorConfig" v-model="itemData.body"></ckeditor>
								<div class="invalid-feedback">
									{{ errors.body }}
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer modal__footer">
					<button
						@click.prevent="clearErrors()"
						class="btn btn--md"
						type="button"
						data-dismiss="modal"
						aria-label="close"
						name="button"
					>
						Cancel
					</button>
					<button
						class="btn btn--md btn__primary"
						type="submit"
						@click.prevent="sendEmails"
						v-bind="{ disabled: loading }"
						name="button"
					>
						Send Email
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import form from '../../mixins/form';
import messages from '../../utils/validations/messages';

export default {
	name: 'Email',
	mixins: [form],
	data() {
		return {
			editor: ClassicEditor,
			editorConfig: {},
			errors: {},
			loading: false,
			itemData: {
				recipients: 'all'
			}
		};
	},
	components: {
		ckeditor: CKEditor.component
	},
	computed: {
		...mapGetters(['getStatus'])
	},
	watch: {
		getStatus(status) {
			if (status === 'success') {
				this.itemData = {};
			}
		}
	},
	methods: {
		...mapActions(['sendEmail', 'sendSingleEmail']),
		sendEmails() {
			this.validate(this.itemData, messages.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}
			const { recipients, ...data } = this.itemData;
			const isIndividual = recipients === 'individual';
			return isIndividual ? this.sendSingleEmail(data) : this.sendEmail(data);
		}
	}
};
</script>
