import Joi from 'joi';

const messages = {
	create: {
		subject: Joi.string().required(),
		body: Joi.string().required(),
		recipients: Joi.string(),
		email: Joi.when('recipients', {
			is: 'individual',
			then: Joi.string().required()
		}),
		firstname: Joi.when('recipients', {
			is: 'individual',
			then: Joi.string().required()
		}),
		lastname: Joi.when('recipients', {
			is: 'individual',
			then: Joi.string().required()
		})
	}
};

export default messages;
