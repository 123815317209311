<!-- eslint-disable max-len -->
<template>
	<div>
		<template v-if="loading">
			<div class="loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div>
				<div class="d-flex d-flex--between">
					<h3>Overview</h3>
					<div class="pad">
						<button
							class="mr-2 btn btn__primary"
							type="button"
							name="button"
							data-toggle="modal"
							data-target="#emailModal"
						>
							<span class="btn__icon">
								<svg width="24" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M8 5H5V2H3v3H0v2h3v3h2V7h3V5zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 0 18 0c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86 0 1.07-.34 2.04-.9 2.86.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 0 13 0c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16C20.45 8.89 21 9.82 21 11v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 8c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"
										fill="#fff"
									/>
								</svg>
							</span>
							Send Email
						</button>
						<button
							class="btn btn__primary btn__outline"
							type="button"
							name="button"
							data-toggle="modal"
							data-target="#notificationModal"
						>
							<span class="btn__icon">
								<svg width="24" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M8 5H5V2H3v3H0v2h3v3h2V7h3V5zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 0 18 0c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86 0 1.07-.34 2.04-.9 2.86.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 0 13 0c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16C20.45 8.89 21 9.82 21 11v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 8c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"
										fill="#fff"
									/>
								</svg>
							</span>
							Send Notification
						</button>
					</div>
				</div>
			</div>

			<section class="main-grid__row overview">
				<router-link :to="{ name: 'investors' }">
					<div class="overview__item">
						<div class="overview__icon green">
							<svg width="44" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M35.621 23.797c.074-.587.129-1.174.129-1.797s-.055-1.21-.129-1.797l3.869-3.025a.925.925 0 0 0 .22-1.173l-3.667-6.343a.916.916 0 0 0-.807-.459c-.11 0-.22.019-.311.055l-4.565 1.834a13.395 13.395 0 0 0-3.099-1.797l-.696-4.858a.894.894 0 0 0-.899-.77h-7.333a.894.894 0 0 0-.898.77l-.697 4.858c-1.118.458-2.145 1.082-3.098 1.797L9.075 9.258a1.038 1.038 0 0 0-.33-.055.908.908 0 0 0-.789.459L4.29 16.005a.904.904 0 0 0 .22 1.173l3.868 3.025A14.538 14.538 0 0 0 8.25 22c0 .605.055 1.21.128 1.797L4.51 26.822a.925.925 0 0 0-.22 1.173l3.666 6.343a.916.916 0 0 0 .807.459c.11 0 .22-.019.312-.055l4.565-1.834c.953.734 1.98 1.339 3.098 1.797l.697 4.858c.055.44.44.77.898.77h7.333c.459 0 .844-.33.899-.77l.696-4.858c1.119-.458 2.145-1.082 3.099-1.797l4.565 1.834c.11.036.22.055.33.055a.908.908 0 0 0 .788-.459l3.667-6.343a.925.925 0 0 0-.22-1.173l-3.869-3.025zm-3.63-3.135c.074.568.092.953.092 1.338 0 .385-.037.788-.092 1.338l-.256 2.072 1.631 1.283 1.98 1.54-1.283 2.219-2.328-.935-1.907-.77-1.65 1.246c-.788.587-1.54 1.027-2.292 1.339l-1.943.788-.293 2.072-.367 2.475h-2.567l-.348-2.475-.293-2.072-1.944-.788a10.4 10.4 0 0 1-2.255-1.302l-1.668-1.283-1.943.788-2.329.935-1.283-2.218 1.98-1.54 1.632-1.284-.257-2.071c-.055-.569-.092-.99-.092-1.357s.037-.788.092-1.338l.257-2.072-1.632-1.283-1.98-1.54 1.283-2.219 2.329.935 1.906.77 1.65-1.246c.789-.587 1.54-1.027 2.292-1.339l1.943-.788.294-2.072.366-2.475h2.549l.348 2.475.293 2.072 1.944.788c.788.33 1.521.752 2.255 1.302l1.668 1.283 1.943-.788 2.329-.935 1.283 2.218-1.962 1.559-1.631 1.283.256 2.072z"
									fill="#fff"
								/>
								<path
									d="M22 14.667A7.331 7.331 0 0 0 14.666 22 7.331 7.331 0 0 0 22 29.333 7.331 7.331 0 0 0 29.333 22 7.331 7.331 0 0 0 22 14.667zm0 11A3.677 3.677 0 0 1 18.333 22 3.677 3.677 0 0 1 22 18.333 3.677 3.677 0 0 1 25.666 22 3.677 3.677 0 0 1 22 25.667z"
									fill="#fff"
								/>
							</svg>
						</div>
						<div class="overview__data" id="investorsTotal">
							<p class="overview__head">Investors</p>
							<template v-if="users.data && users.data.length">
								<h2>{{ users.total }}</h2>
							</template>
							<template v-else>
								<h2>0</h2>
							</template>
						</div>
					</div>
				</router-link>

				<router-link :to="{ name: 'stocks' }">
					<div class="overview__item">
						<div class="overview__icon blue">
							<svg width="44" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M35.621 23.797c.074-.587.129-1.174.129-1.797s-.055-1.21-.129-1.797l3.869-3.025a.925.925 0 0 0 .22-1.173l-3.667-6.343a.916.916 0 0 0-.807-.459c-.11 0-.22.019-.311.055l-4.565 1.834a13.395 13.395 0 0 0-3.099-1.797l-.696-4.858a.894.894 0 0 0-.899-.77h-7.333a.894.894 0 0 0-.898.77l-.697 4.858c-1.118.458-2.145 1.082-3.098 1.797L9.075 9.258a1.038 1.038 0 0 0-.33-.055.908.908 0 0 0-.789.459L4.29 16.005a.904.904 0 0 0 .22 1.173l3.868 3.025A14.538 14.538 0 0 0 8.25 22c0 .605.055 1.21.128 1.797L4.51 26.822a.925.925 0 0 0-.22 1.173l3.666 6.343a.916.916 0 0 0 .807.459c.11 0 .22-.019.312-.055l4.565-1.834c.953.734 1.98 1.339 3.098 1.797l.697 4.858c.055.44.44.77.898.77h7.333c.459 0 .844-.33.899-.77l.696-4.858c1.119-.458 2.145-1.082 3.099-1.797l4.565 1.834c.11.036.22.055.33.055a.908.908 0 0 0 .788-.459l3.667-6.343a.925.925 0 0 0-.22-1.173l-3.869-3.025zm-3.63-3.135c.074.568.092.953.092 1.338 0 .385-.037.788-.092 1.338l-.256 2.072 1.631 1.283 1.98 1.54-1.283 2.219-2.328-.935-1.907-.77-1.65 1.246c-.788.587-1.54 1.027-2.292 1.339l-1.943.788-.293 2.072-.367 2.475h-2.567l-.348-2.475-.293-2.072-1.944-.788a10.4 10.4 0 0 1-2.255-1.302l-1.668-1.283-1.943.788-2.329.935-1.283-2.218 1.98-1.54 1.632-1.284-.257-2.071c-.055-.569-.092-.99-.092-1.357s.037-.788.092-1.338l.257-2.072-1.632-1.283-1.98-1.54 1.283-2.219 2.329.935 1.906.77 1.65-1.246c.789-.587 1.54-1.027 2.292-1.339l1.943-.788.294-2.072.366-2.475h2.549l.348 2.475.293 2.072 1.944.788c.788.33 1.521.752 2.255 1.302l1.668 1.283 1.943-.788 2.329-.935 1.283 2.218-1.962 1.559-1.631 1.283.256 2.072z"
									fill="#fff"
								/>
								<path
									d="M22 14.667A7.331 7.331 0 0 0 14.666 22 7.331 7.331 0 0 0 22 29.333 7.331 7.331 0 0 0 29.333 22 7.331 7.331 0 0 0 22 14.667zm0 11A3.677 3.677 0 0 1 18.333 22 3.677 3.677 0 0 1 22 18.333 3.677 3.677 0 0 1 25.666 22 3.677 3.677 0 0 1 22 25.667z"
									fill="#fff"
								/>
							</svg>
						</div>
						<div class="overview__data" id="totalStocks">
							<p class="overview__head">Stocks</p>
							<template v-if="stocks.data && stocks.data.length">
								<h2>{{ stocks.total }}</h2>
							</template>
							<template v-else>
								<h2>0</h2>
							</template>
						</div>
					</div>
				</router-link>

				<router-link :to="{ name: 'mutual-funds' }">
					<div class="overview__item">
						<div class="overview__icon red">
							<svg width="44" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M35.621 23.797c.074-.587.129-1.174.129-1.797s-.055-1.21-.129-1.797l3.869-3.025a.925.925 0 0 0 .22-1.173l-3.667-6.343a.916.916 0 0 0-.807-.459c-.11 0-.22.019-.311.055l-4.565 1.834a13.395 13.395 0 0 0-3.099-1.797l-.696-4.858a.894.894 0 0 0-.899-.77h-7.333a.894.894 0 0 0-.898.77l-.697 4.858c-1.118.458-2.145 1.082-3.098 1.797L9.075 9.258a1.038 1.038 0 0 0-.33-.055.908.908 0 0 0-.789.459L4.29 16.005a.904.904 0 0 0 .22 1.173l3.868 3.025A14.538 14.538 0 0 0 8.25 22c0 .605.055 1.21.128 1.797L4.51 26.822a.925.925 0 0 0-.22 1.173l3.666 6.343a.916.916 0 0 0 .807.459c.11 0 .22-.019.312-.055l4.565-1.834c.953.734 1.98 1.339 3.098 1.797l.697 4.858c.055.44.44.77.898.77h7.333c.459 0 .844-.33.899-.77l.696-4.858c1.119-.458 2.145-1.082 3.099-1.797l4.565 1.834c.11.036.22.055.33.055a.908.908 0 0 0 .788-.459l3.667-6.343a.925.925 0 0 0-.22-1.173l-3.869-3.025zm-3.63-3.135c.074.568.092.953.092 1.338 0 .385-.037.788-.092 1.338l-.256 2.072 1.631 1.283 1.98 1.54-1.283 2.219-2.328-.935-1.907-.77-1.65 1.246c-.788.587-1.54 1.027-2.292 1.339l-1.943.788-.293 2.072-.367 2.475h-2.567l-.348-2.475-.293-2.072-1.944-.788a10.4 10.4 0 0 1-2.255-1.302l-1.668-1.283-1.943.788-2.329.935-1.283-2.218 1.98-1.54 1.632-1.284-.257-2.071c-.055-.569-.092-.99-.092-1.357s.037-.788.092-1.338l.257-2.072-1.632-1.283-1.98-1.54 1.283-2.219 2.329.935 1.906.77 1.65-1.246c.789-.587 1.54-1.027 2.292-1.339l1.943-.788.294-2.072.366-2.475h2.549l.348 2.475.293 2.072 1.944.788c.788.33 1.521.752 2.255 1.302l1.668 1.283 1.943-.788 2.329-.935 1.283 2.218-1.962 1.559-1.631 1.283.256 2.072z"
									fill="#fff"
								/>
								<path
									d="M22 14.667A7.331 7.331 0 0 0 14.666 22 7.331 7.331 0 0 0 22 29.333 7.331 7.331 0 0 0 29.333 22 7.331 7.331 0 0 0 22 14.667zm0 11A3.677 3.677 0 0 1 18.333 22 3.677 3.677 0 0 1 22 18.333 3.677 3.677 0 0 1 25.666 22 3.677 3.677 0 0 1 22 25.667z"
									fill="#fff"
								/>
							</svg>
						</div>
						<div class="overview__data" id="mutualFundsTotal">
							<p class="overview__head">Mutual Funds</p>
							<template v-if="mutualFunds.data && mutualFunds.data.length">
								<h2>{{ mutualFunds.total }}</h2>
							</template>
							<template v-else>
								<h2>0</h2>
							</template>
						</div>
					</div>
				</router-link>

				<router-link :to="{ name: 'giftCards' }">
					<div class="overview__item">
						<div class="overview__icon orange">
							<svg width="44" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M35.621 23.797c.074-.587.129-1.174.129-1.797s-.055-1.21-.129-1.797l3.869-3.025a.925.925 0 0 0 .22-1.173l-3.667-6.343a.916.916 0 0 0-.807-.459c-.11 0-.22.019-.311.055l-4.565 1.834a13.395 13.395 0 0 0-3.099-1.797l-.696-4.858a.894.894 0 0 0-.899-.77h-7.333a.894.894 0 0 0-.898.77l-.697 4.858c-1.118.458-2.145 1.082-3.098 1.797L9.075 9.258a1.038 1.038 0 0 0-.33-.055.908.908 0 0 0-.789.459L4.29 16.005a.904.904 0 0 0 .22 1.173l3.868 3.025A14.538 14.538 0 0 0 8.25 22c0 .605.055 1.21.128 1.797L4.51 26.822a.925.925 0 0 0-.22 1.173l3.666 6.343a.916.916 0 0 0 .807.459c.11 0 .22-.019.312-.055l4.565-1.834c.953.734 1.98 1.339 3.098 1.797l.697 4.858c.055.44.44.77.898.77h7.333c.459 0 .844-.33.899-.77l.696-4.858c1.119-.458 2.145-1.082 3.099-1.797l4.565 1.834c.11.036.22.055.33.055a.908.908 0 0 0 .788-.459l3.667-6.343a.925.925 0 0 0-.22-1.173l-3.869-3.025zm-3.63-3.135c.074.568.092.953.092 1.338 0 .385-.037.788-.092 1.338l-.256 2.072 1.631 1.283 1.98 1.54-1.283 2.219-2.328-.935-1.907-.77-1.65 1.246c-.788.587-1.54 1.027-2.292 1.339l-1.943.788-.293 2.072-.367 2.475h-2.567l-.348-2.475-.293-2.072-1.944-.788a10.4 10.4 0 0 1-2.255-1.302l-1.668-1.283-1.943.788-2.329.935-1.283-2.218 1.98-1.54 1.632-1.284-.257-2.071c-.055-.569-.092-.99-.092-1.357s.037-.788.092-1.338l.257-2.072-1.632-1.283-1.98-1.54 1.283-2.219 2.329.935 1.906.77 1.65-1.246c.789-.587 1.54-1.027 2.292-1.339l1.943-.788.294-2.072.366-2.475h2.549l.348 2.475.293 2.072 1.944.788c.788.33 1.521.752 2.255 1.302l1.668 1.283 1.943-.788 2.329-.935 1.283 2.218-1.962 1.559-1.631 1.283.256 2.072z"
									fill="#fff"
								/>
								<path
									d="M22 14.667A7.331 7.331 0 0 0 14.666 22 7.331 7.331 0 0 0 22 29.333 7.331 7.331 0 0 0 29.333 22 7.331 7.331 0 0 0 22 14.667zm0 11A3.677 3.677 0 0 1 18.333 22 3.677 3.677 0 0 1 22 18.333 3.677 3.677 0 0 1 25.666 22 3.677 3.677 0 0 1 22 25.667z"
									fill="#fff"
								/>
							</svg>
						</div>
						<div class="overview__data" id="giftCardsTotal">
							<p class="overview__head">Gift Cards</p>
							<template v-if="giftCards.data && giftCards.data.length">
								<h2>{{ giftCards.total }}</h2>
							</template>
							<template v-else>
								<h2>0</h2>
							</template>
						</div>
					</div>
				</router-link>
			</section>

			<section class="mt-5">
				<div class="row row-cols-1 row-cols-3">
					<div class="col mb-4">
						<div class="card h-100 pt-2">
							<img src="../assets/img/bank.svg" class="card-img-top" alt="Banks" style="height: 7rem;" />
							<div class="card-desc">
								<h3>Banks</h3>
								<p>View existing and create new banks.</p>
								<router-link class="btn-card" :to="{ name: 'banks' }">View Banks</router-link>
							</div>
						</div>
					</div>
					<div class="col mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/gear.svg"
								class="card-img-top"
								alt="settings"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Settings</h3>
								<p>You can Manage and configure system settings here.</p>
								<router-link class="btn-card" :to="{ name: 'settings' }">View Settings</router-link>
							</div>
						</div>
					</div>
					<div class="col mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/Audit.svg"
								class="card-img-top"
								alt="Audits"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Audits</h3>
								<p>Audits holds all the activities that happens on our platform.</p>
								<router-link class="btn-card" :to="{ name: 'audits' }">View Audits</router-link>
							</div>
						</div>
					</div>
					<div class="col mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/coin.svg"
								class="card-img-top"
								alt="Withdrawals"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Withdrawals</h3>
								<p>
									Manage all withdrawal request here. Decline or accept a request as the case may be.
								</p>
								<router-link class="btn-card" :to="{ name: 'withdrawalRequests' }">
									View Withdrawals
								</router-link>
							</div>
						</div>
					</div>
				</div>

				<div class="row row-cols-1 row-cols-3 mt-4">
					<div class="col mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/checklist.svg"
								class="card-img-top"
								alt="Checklist"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Order</h3>
								<p>View All the stock orders that has been made by investors.</p>
								<router-link class="btn-card" :to="{ name: 'orders' }">View Order</router-link>
							</div>
						</div>
					</div>
					<div class="col mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/save.svg"
								class="card-img-top"
								alt="Savings"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Savings</h3>
								<p>View all the current savings plan operated by investors.</p>
								<router-link class="btn-card" :to="{ name: 'savings' }">View Savings</router-link>
							</div>
						</div>
					</div>
					<div class="col mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/referral.svg"
								class="card-img-top"
								alt="Referral"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Referral</h3>
								<p>View all referrals that has been made by users.</p>
								<router-link class="btn-card" :to="{ name: 'referrals' }">View Referral</router-link>
							</div>
						</div>
					</div>
					<div class="col mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/money.svg"
								class="card-img-top"
								alt="Commission"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Commission</h3>
								<p>View all commissions that has happened in the system.</p>
								<router-link class="btn-card" :to="{ name: 'commissions' }">
									View Commission
								</router-link>
							</div>
						</div>
					</div>
				</div>

				<div class="row row-cols-1 row-cols-3 mt-4">
					<div class="col-md-3 mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/dollar.svg"
								class="card-img-top"
								alt="Commission"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>User Mutual Funds</h3>
								<p>View all users' mutual funds that have happened in the system.</p>
								<router-link class="btn-card" :to="{ name: 'investor-mf' }">
									User Mutual Funds
								</router-link>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/overview-mutualfunds.svg"
								class="card-img-top"
								alt="Commission"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Mutual Funds</h3>
								<p>View all Mutual Funds.</p>
								<router-link class="btn-card" :to="{ name: 'mutual-funds' }">
									View Mutual Funds
								</router-link>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/overview-investors.svg"
								class="card-img-top"
								alt="Commission"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Investors</h3>
								<p>View all investors.</p>
								<router-link class="btn-card" :to="{ name: 'investors' }">
									View Investors
								</router-link>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/overview-stocks.svg"
								class="card-img-top"
								alt="Commission"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Stocks</h3>
								<p>View all Stocks.</p>
								<router-link class="btn-card" :to="{ name: 'stocks' }">
									View Stocks
								</router-link>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/overview-giftcards.svg"
								class="card-img-top"
								alt="Commission"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Gift Cards</h3>
								<p>View all Stocks.</p>
								<router-link class="btn-card" :to="{ name: 'giftCards' }">
									View Gift Cards
								</router-link>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-4">
						<div class="card h-100 pt-2">
							<img
								src="../assets/img/faq.svg"
								class="card-img-top"
								alt="Commission"
								style="height: 7rem;"
							/>
							<div class="card-desc">
								<h3>Faqs</h3>
								<p>View all Faqs.</p>
								<router-link class="btn-card" :to="{ name: 'faqs' }">
									View Faqs
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Email />
			<Notification />
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Loader from '../components/Loader';
import Email from '../components/messages/Email';
import Notification from '../components/messages/Notification';
import api from '../utils/api';

export default {
	name: 'Overview',
	components: {
		Loader,
		Email,
		Notification
	},
	data() {
		return {
			users: {},
			stocks: {},
			mutualFunds: {},
			giftCards: {},
			statsLoaded: false,
			loading: true
		};
	},
	created() {
		this.getStats();
	},
	computed: {
		...mapGetters(['getTotalOrders'])
	},
	methods: {
		getStats() {
			axios
				.all([
					api.get('users'),
					api.get('instruments'),
					api.get('mutualfunds?status=all'),
					api.get('giftcards')
				])
				.then(
					axios.spread((users, stocks, mutualFunds, giftCards) => {
						this.users = users.data;
						this.stocks = stocks.data;
						this.mutualFunds = mutualFunds.data;
						this.giftCards = giftCards.data;
						this.statsLoaded = true;
					})
				)
				.catch((error) => {
					this.statsLoaded = false;
					return error;
				})
				.finally(() => {
					this.loading = false;
				});
			return true;
		}
	}
};
</script>

<style lang="scss" scoped>
.loading {
	min-height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pad {
	padding-left: 35%;
	margin-left: 15%;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px repeat(3, 2fr) repeat(4, 100px) 120px;
	font-size: 14px;
	margin-bottom: 7px;
	border-radius: 4px;
}

//
section {
	padding: 50px 0;
}
.card-desc {
	padding: 1.25rem;
	h3 {
		color: #000000;
		font-weight: 600;
		font-size: 1.5em;
		line-height: 1.3em;
		margin-top: 0;
		margin-bottom: 5px;
		padding: 0;
	}
	p {
		color: #747373;
		font-size: 14px;
		font-weight: 400;
		font-size: 1em;
		line-height: 1.5;
		margin: 0px;
		margin-bottom: 20px;
		padding: 0;
		font-family: 'Raleway', sans-serif;
	}
}
.btn-card {
	background-color: #1abc9c;
	color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	padding: 0.54rem 1.14rem;
	font-size: 0.81rem;
	-webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	-o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	margin: 0;
	border: 0;
	-webkit-border-radius: 0.125rem;
	border-radius: 0.125rem;
	cursor: pointer;
	text-transform: uppercase;
	white-space: normal;
	word-wrap: break-word;
	color: #fff;
	&:hover {
		background: orange;
	}
}
a.btn-card {
	text-decoration: none;
	color: #fff;
	margin: 1rem 0;
}
</style>
